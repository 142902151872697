import FollowIcons from './follow-icons'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './footer.scss'
import './mailchimp.scss'

const CustomForm = ({ status, message, onValidated }) => {
    let email

    const submit = (event) => {
        event.preventDefault()

        return email && email.value.indexOf('@') > -1 &&
        onValidated({
            EMAIL: email.value,
        })
    }

    return (
        <form className="mailchimp" onSubmit={(event) => submit(event)}>
            {status === 'sending' && <div>sending...</div>}
            {status === 'error' && (
                <div dangerouslySetInnerHTML={{ __html: message }}/>
            )}
            {status === 'success' && (
                <div>We&apos;re nearly there - we just need to confirm your email address. To finish subscribing to the Springboard Newsletter, please click the link in the email we&apos;ve sent you!</div>
            )}
            <br />
            <input
                ref={(node) => (email = node)}
                type="email"
                placeholder="Your email"
            />
            <br />
            <button type="submit" onClick={submit}>
                Sign up
            </button>
        </form>
    )
}

CustomForm.propTypes = {
    status: PropTypes.string,
    message: PropTypes.string,
    onValidated: PropTypes.func,
}

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-wrapper constrain-width">
                    <div className="footer-section">
                        <h6 className="footer-section-title">Quick links</h6>
                        <ul>
                            <li><Link className="sub-navigation-item" to="/what-we-do/learning-events/">Learn with us</Link></li>
                            <li><Link className="sub-navigation-item" to="/contact">Contact</Link></li>
                            <li><Link className="sub-navigation-item" to="/partner-with-us/become-a-volunteer/">Become a Volunteer</Link></li>
                            <li><Link className="sub-navigation-item" to="/partner-with-us/become-a-partner/">Become a Strategic Partner</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h6 className="footer-section-title">Find us at</h6>
                        <FollowIcons/>
                    </div>
                    <div className="footer-section mailchimp">
                        <h6 className="footer-section-title">Stay up to date with the Springboard Newsletter</h6>
                        <a className="has-button" href="https://ncv.microsoft.com/bhnkreBHx1" target="_blank" rel="noopener noreferrer"><button className="button inverted">Sign up</button></a>
                        <ul>
                            <li><Link to="/terms-of-use/">Terms of Use</Link> & <Link to="/privacy-policy/">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="copyright text-center">
                    &copy; {new Date().getFullYear()} Springboard Trust
                </div>
            </footer>
        )
    }
}

export default Footer
