import Icon from './icon'
import React from 'react'

import './share-icons.scss'
import './follow-icons.scss'

function FollowIcons() {
    return (
        <div className="share-icons follow">
            <a href="https://www.facebook.com/SpringboardTrustNZ" target="_blank" rel="noopener noreferrer">
                <Icon name="facebook"/>
            </a>
            <a href="https://www.linkedin.com/company/springboardtrust" target="_blank" rel="noopener noreferrer">
                <Icon name="linkedin"/>
            </a>
            <a href="https://twitter.com/Springboard_NZ" target="_blank" rel="noopener noreferrer">
                <Icon name="twitter"/>
            </a>
        </div>
    )
}

export default FollowIcons
