import { graphql, useStaticQuery } from 'gatsby'

export const usePartnerLogoData = () => {
    return useStaticQuery(
        graphql`
            query dabomb {
                contentfulLogosContentBlock(contentful_id: {eq: "4HSxzSiuBQxWGA4KsJMXBy"}) {
    title
    logos {
      title
      url
      logo {
        title
        resize(width: 600, resizingBehavior: SCALE) {
          aspectRatio
          base64
          src
        }
      }
    }
    contentful_id
  }
            }
        `,
    )
}
