import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
export const useMainMenuData = () => {
    const { contentfulNavigation } = useStaticQuery(
        graphql`
            query navigation {
                contentfulNavigation {
                    title
                    navigationItems {
                        title
                        link {
                            slug
                        }
                        menuItems {
                            ... on ContentfulPage {
                                id
                                title
                                slug
                            }
                        }
                        id
                        url
                    }
                }
            }

        `,
    )

    return contentfulNavigation
}

useMainMenuData.propTypes = {
    title: PropTypes.isRequired,
    navigationItems: PropTypes.shape({
        menuItems: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    }),
}
