/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import ErrorBoundary from './error-boundary'
import Footer from './footer'
import Header from './header'
import { Helmet } from 'react-helmet'
import Partners from './partners'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import '../styles/index.scss'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css?family=Montserrat:400,600|Raleway:400,600&display=swap&subset=latin-ext"
                    rel="stylesheet"/>
                <script type="application/ld+json">{JSON.stringify([
                    {
                        '@context': 'http://schema.org',
                        '@type': 'WebSite',
                        name: data.site.siteMetadata.title,
                        url: process.env.GATSBY_SPA_URL,
                        sameAs: [
                            'https://www.linkedin.com/company/springboardtrust',
                            'https://twitter.com/Springboard_NZ',
                            'https://www.facebook.com/SpringboardTrustNZ',
                        ],
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: `${process.env.GATSBY_SPA_URL}/search?query={search_term_string}`,
                            'query-input': 'required name=search_term_string',
                        },
                    },
                    {
                        '@context': 'http://schema.org',
                        '@type': 'EducationalOrganization',
                        name: data.site.siteMetadata.title,
                        url: process.env.GATSBY_SPA_URL,
                        sameAs: [
                            'https://www.linkedin.com/company/springboardtrust',
                            'https://twitter.com/Springboard_NZ',
                            'https://www.facebook.com/SpringboardTrustNZ',
                        ],
                        address: {
                            '@type': 'PostalAddress',
                            streetAddress: 'Level 1, Raphoe House, 8 Gloucester Park Road',
                            addressLocality: 'Onehunga',
                            addressRegion: 'Auckland',
                            postalCode: '1061',
                            addressCountry: 'NZ',
                        },
                        telephone: '+64993797877',
                        email: 'admin@springboardtrust.org.nz',
                    },
                ])}</script>
            </Helmet>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <ErrorBoundary>
                <div className="constrain-width">
                    <main>{children}</main>
                </div>
            </ErrorBoundary>
            <Partners/>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string,
            }),
        }),
    }),
}

export default Layout
