import Classnames from 'classnames'
import Icon from './icon'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import PropTypes from 'prop-types'
import { useMainMenuData } from '../hooks/main-menu'
import React, { useState } from 'react'
import './header.scss'
import './navigation.scss'

export default function Header({ siteTitle }) {
    const [activeNavigationItem, setActiveNavigationItem] = useState('')
    const [isMenuActive, toggleMenu] = useState(false)

    const { navigationItems } = useMainMenuData()

    function toggleActiveMenu(menuID) {
        setActiveNavigationItem(menuID)
    }

    function toggleMenuActive() {
        toggleMenu(!isMenuActive)
    }

    return (
        <header className="header">
            <div className="header-wrapper">
                <Link className="logo" to="/">
                    <Icon name="logo" title={siteTitle}/>
                </Link>
                <div className={`navigation-bar ${isMenuActive && 'is-active'}`}>
                    <div className="header-actions">
                        { isMenuActive ?
                            <button className="action-button" onClick={() => { toggleMenuActive() }}>
                                <span className="action-button-inner">
                                    <Icon name="close"/>
                                    <span>Close</span>
                                </span>
                            </button>
                            :
                            <button className="action-button" onClick={() => { toggleMenuActive() }}>
                                <span className="action-button-inner">
                                    <Icon name="menu"/>
                                    <span>Menu</span>
                                </span>
                            </button>
                        }
                    </div>
                    <div className="navigation-bar-inner">
                        <nav className="navigation">
                            <div className="navigation-item hidden-desktop">
                                <Link to="/search" className="button navigation-link">Search</Link>
                            </div>
                            { navigationItems && navigationItems.map((navigationItem, key) => {
                                let navigationLink = null

                                if (navigationItem.url) {
                                    navigationLink = navigationItem.url
                                }

                                if (navigationLink === null && navigationItem.link && navigationItem.link.slug) {
                                    navigationLink = navigationItem.link.slug === 'home' ? '/' : `/${navigationItem.link.slug}/`
                                }

                                return (
                                    <Location key={key}>
                                        {({ location }) => {
                                            const navigationItemClass = Classnames('navigation-item', {
                                                'is-active': activeNavigationItem === navigationItem.id,
                                                'is-current': location.pathname === navigationLink,
                                            })

                                            return (
                                                <div className={navigationItemClass} id={navigationItem.id}>
                                                    {
                                                        navigationItem.url ?
                                                            <a href={navigationItem.url} className="button navigation-link">{navigationItem.title}</a>
                                                            :
                                                            <Link to={`${navigationLink}`} className="button navigation-link">{navigationItem.title}</Link>
                                                    }
                                                    {
                                                        navigationItem.menuItems &&
                                                        <React.Fragment>
                                                            <button className="children-toggle" onClick={() => { toggleActiveMenu(navigationItem.id) }}>
                                                                <Icon className="chevron" name="chevron"/>
                                                            </button>
                                                            <nav className="sub-navigation">
                                                                {
                                                                    navigationItem.menuItems.map((menuItem, key) => {
                                                                        return (
                                                                            <Link to={`${menuItem.slug === 'home' ? '/' : `/${menuItem.slug}/`}`} className="sub-navigation-item" key={key}>{menuItem.title}</Link>
                                                                        )
                                                                    })
                                                                }
                                                            </nav>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            )
                                        }}
                                    </Location>
                                )
                            })}
                            <button className="search hidden-mobile">
                                <Link to="/search">
                                    <Icon name="search"/>
                                </Link>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}
