import Img from 'gatsby-image'
import React from 'react'
import { usePartnerLogoData } from '../hooks/partner-logos'

import './partners.scss'

function Partners() {
    const logoData = usePartnerLogoData()
    const { title, logos } = logoData.contentfulLogosContentBlock

    return (
        <div className="partners constrain-width">
            <h4 className="text-center">{title}</h4>
            <div className="partners-wrapper">
                {logos.map((logo, key) => {
                    return (
                        <a
                            className="partner-logo"
                            href={logo.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={key}
                        >
                            <Img fluid={logo.logo.resize} alt={logo.title} />
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

export default Partners
