import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Icon extends Component {
    render() {
        const { name, title, className } = this.props

        const iconClass = className ? `icon ${className}` : 'icon'

        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={iconClass}>
                <title>{title}</title>
                <use xlinkHref={`/_icon.svg#${name}`}/>
            </svg>
        )
    }
}

Icon.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
}

export default Icon
